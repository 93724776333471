tarteaucitron.init({
  "privacyUrl": "https://www.empshare.net/en/pages/legalnotices", /* Privacy policy url */

  "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
  "cookieName": "tarteaucitron", /* Cookie name */

  "orientation": "top", /* Banner position (top - bottom) */
  "showAlertSmall": true, /* Show the small banner on bottom right */
  "cookieslist": true, /* Show the cookie list */

  "adblocker": false, /* Show a Warning if an adblocker is detected */
  "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
  "highPrivacy": false, /* Disable auto consent */
  "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

  "removeCredit": true, /* Remove credit link */
  "moreInfoLink": false, /* Show more info link */
  "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

  "readmoreLink": "/cookiespolicy", /* Change the default readmore link */

  "mandatory": false, /* Show a message about mandatory cookies */
});